import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { Hero, Announcement, Journey, ProblemStatement, Impact, SupportersFunders, FutureFinderAnnouncement, ThreeStepSolution, Footer } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";
// import Proof from "../sections/home/SocialProof.js";

// TODO: #38 Home - break out components + queries into Sections
// TODO: #39 Home - social proof
// TODO: #60 Journey component - fix colors for top header

const Home = ({ data }) => {
  // console.log(data)
  const holdImpactData = data.impactSection.nodes;
  const holdSupportersFundersData = data.supportersFundersImages.nodes;
  const holdSolutionData = data.threeSteps.nodes;
  const holdBestFitUsersData = data.bestFitUsers.nodes;
  holdBestFitUsersData.shift();
  // console.log("REMOVED: " + JSON.stringify(holdBestFitUsersData));

  const stepOneData = {
    stepHeroImageUrl: holdSolutionData[1].data.aws_url__from_section_images_[0],
    stepHeroImageAlt: holdSolutionData[1].data.alt_image_text,
    stepHeaderCopy: holdSolutionData[1].data.header,
    stepSubheadingCopy: holdSolutionData[1].data.subheader,

  }
  const stepTwoData = {
    stepHeroImageUrl: holdSolutionData[2].data.aws_url__from_section_images_[0],
    stepHeroImageAlt: holdSolutionData[2].data.alt_image_text,
    stepHeaderCopy: holdSolutionData[2].data.header,
    stepSubheadingCopy: holdSolutionData[2].data.subheader,

  }
  const stepThreeData = {
    stepHeroImageUrl: holdSolutionData[3].data.aws_url__from_section_images_[0],
    stepHeroImageAlt: holdSolutionData[3].data.alt_image_text,
    stepHeaderCopy: holdSolutionData[3].data.header,
    stepSubheadingCopy: holdSolutionData[3].data.subheader,

  }

  return (
    <Layout
      pageTitle='Home'
    >
      <Announcement 
        containerBackgroundColor='var(--bf-core-teal)'
        textColor='white'
        copy={data.homeAnnouncement.data.header}
      />
      
      <Hero 
        variant='SevenFiveRatio'
        heroBackgroundColor='rgb(244, 244, 244)'
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        headingCopy={data.homeHeader.data.header}
        subheadingCopy={data.homeHeader.data.subheader}
        buttonLabel={data.homeHeader.data.action}
        buttonUrl={data.homeHeader.data.action_url}
        heroImageUrl={`https://company-website-media.s3.amazonaws.com/home/${data.homeHeader.data.attached_images[0].filename}`}
        heroImageAlt={data.homeHeader.data.attached_images[0].filename}
      />
      {/* <Proof /> */}
      <FutureFinderAnnouncement
        headingCopy={data.futureFinderAnnouncement.data.header} 
        heroImageUrl={`https://company-website-media.s3.amazonaws.com/home/${data.futureFinderAnnouncement.data.attached_images[0].filename}`} 
        heroImageAlt={data.futureFinderAnnouncement.data.alt_image_text}
        bodyCopy={data.futureFinderAnnouncement.data.subheader}
      />

      <ProblemStatement 
        variant='FourFourFourRatio'
        problemHeadingCopy={data.problemStatement.data.header}
        problemSubheadingCopy={data.problemStatement.data.subheader}
        resultHeadingCopy={data.resultStatement.data.header}
        resultSubheadingCopy={data.resultStatement.data.subheader}
        dataHeadingCopy={data.resultStatement.data.subheader_2}
        dataSubheadingCopy='in benefits goes unclaimed by eligible students'
        problemPictureOne={data.problemStatement.data.aws_url__from_section_images_[0]}
        problemPictureTwo={data.problemStatement.data.aws_url__from_section_images_[1]}
        problemPictureThree={data.problemStatement.data.aws_url__from_section_images_[2]}
      />

      <ThreeStepSolution
        heroBackgroundColor='rgb(244, 244, 244)'
        headingCopy={holdSolutionData[0].data.header} 
        subheadingCopy={holdSolutionData[0].data.subheader} 
        buttonLabel={holdSolutionData[0].data.action} 
        buttonUrl={holdSolutionData[0].data.action_url}
        stepOneData={stepOneData}
        stepTwoData={stepTwoData}
        stepThreeData={stepThreeData}

      />

      <Journey
        userExamples={data.bestFitUser.data}
        userStats={holdBestFitUsersData}
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}
      />

      <Impact
        variant='SevenFourRatio'
        headingCopyObject={holdImpactData[0].data}
        DataPointOne={holdImpactData[1].data}
        DataPointTwo={holdImpactData[2].data}
      />

      <SupportersFunders
        headingCopy={data.supportersFundersSection.data.header}
        subheadingCopy={data.supportersFundersSection.data.subheader}
        imageData={holdSupportersFundersData}
      />

      <Footer />

    </Layout>
  )
}

export const query = graphql`
  query HomePageContentQuery {
    homeHeader: airtable(
        table: {eq: "Content"},
        data: {Segment: {eq: "Header"}}, 
        ) {
          data {
            header
            status
            subheader
            action
            action_url
            attached_images {
              filename
            }
            aws_url__from_section_images_
          }
      }
    homeAnnouncement: airtable(
      table: {eq: "Content"},
      data: {Segment: {eq: "Announcement"}}, 
      ) {
        data {
          header
          status
        }
      }
    futureFinderAnnouncement: airtable(
      data: {Segment: {eq: "Future Finder announcement"}, page_title: {eq: "Home"}}
    ) {
      data {
        header
        subheader
        attached_images {
          filename
        }
        aws_url__from_section_images_
        alt_image_text
      }
    }
    problemStatement: airtable(
      data: {Segment: {eq: "Problem"}, page_title: {eq: "Home"}}) {
        data {
          attached_images {
            filename
          }
          aws_url__from_section_images_
          header
          subheader
          subheader_2
      }
    }
    resultStatement: airtable(
      data: {Segment: {eq: "Result"}}) {
        data {
          header
          subheader
          subheader_2
      }
    }
    threeSteps: allAirtable(
      filter: {data: {page_title: {eq: "Home"}, section_title: {eq: "Solution"}}}
      sort: {fields: data___Segment}
    ) {
      nodes {
        data {
          header
          subheader
          subheader_2
          attached_images {
            filename
          }
          aws_url__from_section_images_
          Segment
          alt_image_text
          action
          action_url
        }
      }
    }
    bestFitUser: airtable(data: {Segment: {eq: "BestFit Users"}}) {
      id
      data {
        header
        subheader
        subheader_2
        alt_image_text
        aws_url__from_section_images_
      }
    }
    bestFitUsers: allAirtable(
      filter: {data: {section_id: {eq: "recFapEMS6e7K24QF"}}}
      sort: {fields: data___Segment}
    ) {
      nodes {
        data {
          header
          subheader
          subheader_2
          aws_url__from_section_images_
          alt_image_text
          attached_images {
            filename
          }
          Segment
        }
      }
    }
    bestFitUsersInfo: allAirtable(
      filter: {data: {Segment: {regex: "/UserStat/g"}}}
      sort: {fields: data___Segment}
    ) {
      nodes {
        data {
          header
          subheader
          subheader_2
          aws_url__from_section_images_
          alt_image_text
          Segment
        }
      }
    }
    impactSection: allAirtable(
      filter: {data: {section_title: {regex: "/Impact/g"}, page_title: {eq: "Home"}}}
      sort: {fields: data___Segment}
    ) {
      nodes {
        data {
          subheader
          subheader_2
          header
          Segment
        }
      }
    }
    supportersFundersSection: airtable(data: {Segment: {eq: "Supporters & Funders"}}) {
      data {
        header
        subheader
      }
    }
    supportersFundersImages: allAirtableAllImagesTable(
      filter: {data: {page__from_content_: {eq: "Home"}, Content: {eq: "recEPfsMaRqjw8i63"}}}
      sort: {fields: data___section_order}
    ) {
      nodes {
        data {
          file_name
          redirect_url
          attachments {
            filename
          }
          aws_url
        }
      }
    }
  }
`

export default Home;